import { Link } from 'gatsby';
import * as React from 'react';
import { useContext } from 'react';
import { ProjectsData } from '../../../../../types/types';
import { Context } from '../../../../Store/store';
import ProjectsBlocksItemImage from './ProjectsBlockItemImage/projectsBlockItemImage';
import ProjectsBlockItemInfo from './ProjectsBlockItemInfo/projectsBlockItemInfo';
import './projectsBlocksItem.css';
interface ProjectItemProps {
  node: ProjectsData;
  ind: number;
  dataSal?: 'fade';
}

const ProjectsBlocksItem: React.FunctionComponent<ProjectItemProps> = ({ node, dataSal, ind }) => {
  const { state } = useContext(Context);

  return (

    <Link data-sal={dataSal} className="Projects-blocks-item Projects-blocks-one" to={`/${node.id}`}>
      <ProjectsBlockItemInfo
        projectCompany={node.created_time}
        projectName={node.child_page && node.child_page.title}
      />
      <ProjectsBlocksItemImage ind={ind} name={node.name} previewImg={node.previewImg} />
    </Link>
  );
};

export default ProjectsBlocksItem;
