import * as React from 'react';
import './heroContainer.css';
import HeroHeadingContainer from './HeroHeadingContainer/heroHeadingContainer';

const HeroContainer = ({description, heading}) => (
    <div className="Hero-container">
        <HeroHeadingContainer description={description} heading={heading}/>
    </div>
);

export default HeroContainer;
