import * as React from 'react';
import './projectsBlockItemHeader.css';
import ProjectsBlockItemHeading from './ProjectsBlockItemHeading/projectsBlockItemHeading';

interface ProjectsBlockItemHeaderProps {
  heading: string;
}

const ProjectsBlockItemHeader: React.FunctionComponent<ProjectsBlockItemHeaderProps> = ({ types, heading }) => (
    <div className="Projects-block-item-header">
      <ProjectsBlockItemHeading heading={heading}/>
    </div>
);

export default ProjectsBlockItemHeader;
