import axios from 'axios';
import { graphql } from 'gatsby';
import { useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Hero from '../components/Hero/hero';
import Layout from '../components/layout';
import Projects from '../components/Projects/projects';
import SEO from '../components/SEO/seo';
import Spinner from '../components/Spinner/spinner';
import { Data } from '../data/projectsData';

const DesignPage = () => {
  const [cases, setCases] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (process.env.GATSBY_GET_CASES) {
      setLoading(true);
      axios.get(process.env.GATSBY_GET_CASES)
        .then(res => {
          const caseList = res.data.results.filter(c => c.type === 'child_page');

          setCases(caseList);
          setLoading(false);
        })
    }
  }, [])

  return (
    <Layout mainpage>
      <SEO lang="en" description="Artyom Alekseev — Product Designer, frontend develop and person who love to do what he love." title={'Main'} keywords={['designer', 'artyom', 'alekseev', 'Artyom Alekseev', 'ux', 'ui']}>
      </SEO>
      <Hero />
      {loading && <Spinner />}
      {cases && <Projects projects={cases} />}
    </Layout>

  );
};

export default DesignPage;
