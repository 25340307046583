import * as React from 'react';
import './projectsBlockItemSmall.css';

interface ProjectsBlockItemSmallProps {
  small: string;
}

const ProjectsBlockItemSmall: React.FunctionComponent<ProjectsBlockItemSmallProps> = ({ small }) => (
    <small className="Projects-block-item-small">{small}</small>
);

export default ProjectsBlockItemSmall;
