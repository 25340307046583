import React from 'react';
import './projectsBlockItemImage.css';
import { Bar } from '@visx/shape';
import { PatternLines, Pattern as CustomPattern, PatternWaves } from '@visx/pattern';


interface ProjectItemImageProps {
  previewImg: string;
  ind: number;
  name: string;
}

const ProjectsBlocksItemImage = ({ previewImg, name, ind }: ProjectItemImageProps) => (
      <svg className="Projects-blocks-item-img-container" >
        <PatternLines
          id={"pattern-0"}
          height={12}
          width={12}
          stroke="black"
          strokeWidth={2}
          orientation={['horizontal']}
        />
        <CustomPattern id={"pattern-1"} width={20} height={20}>
        <animateTransform
          attributeType="xml"
          attributeName="patternTransform"
          type="translate"
          from="0 0"
          to="0 100"
          dur="5s"
          repeatCount="indefinite"
        />
        <circle cx={5} cy={5} r="4" stroke="none" fill="black" transform-origin="center" />
    </CustomPattern>
    <CustomPattern id={"pattern-2"} width={20} height={20}>
          <animateTransform
            attributeType="xml"
            attributeName="patternTransform"
            type="translate"
            from="0 0"
            to="100 0"
            dur="5s"
            repeatCount="indefinite"
          />
        <path
          d={`M 0 ${20 / 2} c ${20 / 8} ${-20 / 4} , ${(20 * 3) / 8} ${
            -20 / 4
          } , ${20 / 2} 0
               c ${20 / 8} ${20 / 4} , ${(20 * 3) / 8} ${20 / 4} , ${
            20 / 2
          } 0 M ${-20 / 2} ${20 / 2}
               c ${20 / 8} ${20 / 4} , ${(20 * 3) / 8} ${20 / 4} , ${
            20 / 2
          } 0 M ${20} ${20 / 2}
               c ${20 / 8} ${-20 / 4} , ${(20 * 3) / 8} ${-20 / 4} , ${
            20 / 2
          } 0`}
          fill="none"
          stroke="black"
          strokeWidth={1}
        />
      </CustomPattern>
      <PatternLines
      id={"pattern-3"}
      height={24}
      width={24}
      stroke="black"
      strokeWidth={1}
      orientation={['vertical', 'horizontal']}
    />
    <PatternLines
      id={"pattern-4"}
      height={12}
      width={12}
      stroke="black"
      strokeWidth={1}
      orientation={['diagonalRightToLeft']}
    />
    <PatternLines
      id={"pattern-5"}
      height={12}
      width={12}
      stroke="black"
      strokeWidth={1}
      orientation={['horizontal']}
    />
        <PatternWaves id={"pattern-6"} height={12} width={12} fill="transparent" stroke="black" strokeWidth={1} />
      <Bar
                fill={`url(#pattern-${ind})`}
                x={0}
                y={0}
                width="100%"
                height="100%"
                rx={14}
      />

      </svg>
) ;

export default ProjectsBlocksItemImage;
