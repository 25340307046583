import * as React from 'react';
import HeroHeadingTextCompany, { CompanyInfo } from '../HeroHeadingTextCompany/heroHeadingTextCompany';
import './heroHeadingTextMD.css';

interface HeroHeadingTextMDProps {
  text: string;
  company?: CompanyInfo;
}

const HeroHeadingTextMD: React.FunctionComponent<HeroHeadingTextMDProps> = ({ text, company }) => (
    <h1 className="Hero-heading_md">
        {text}
    </h1>
);

export default HeroHeadingTextMD;
