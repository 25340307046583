import React from 'react';
import { ProjectsData } from '../../types/types';
import './projects.css';
import ProjectsBlocks from './ProjectsBlocksWrap/ProjectsBlocks/projectsBlocks';

interface ProjectsProps {
  projects: ProjectsData[];
}

const Projects = ({ projects }: ProjectsProps) => (
    <section className="Projects">
            <ProjectsBlocks projects={projects}/>
    </section>
);

export default Projects;
