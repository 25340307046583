import * as React from 'react';
import './heroHeadingTextLG.css';

interface HeroHeadingTextLGProps {
  text: string;
}

const HeroHeadingTextLG: React.FunctionComponent<HeroHeadingTextLGProps> = ({ text }) => (
    <h1 className="Hero-heading_lg">
        {text}
    </h1>
);

export default HeroHeadingTextLG;
