import * as React from 'react';
import { Parallax } from 'react-scroll-parallax';
import ProjectsBlockItemHeader from './ProjectsBlockItemHeader/projectsBlockItemHeader';
import './projectsBlockItemInfo.css';
import ProjectsBlockItemSmall from './ProjectsBlockItemSmall/projectsBlockItemSmall';

interface ProjectsBlockItemInfoProps {
  projectName: string;
  projectCompany: string;
}

const ProjectsBlockItemInfo: React.FunctionComponent<ProjectsBlockItemInfoProps> = ({ projectName, projectCompany }) => {
  const getIconSrc = (title: string): string | null => {
    const logosUrl = "./images/logos/";
    switch (title) {
      case "tsimmes":
        return logosUrl + 'tsimmes.png';
      case "cactus":
        return logosUrl + 'cactus.png';
      case "komilfo":
        return logosUrl + 'komilfo.png';
      case "ooc":
        return logosUrl + 'ooc.png';
      case "polytech":
        return logosUrl + 'polytech.png';
      case "snob":
        return logosUrl + 'snob.png';
      case "sushi":
        return logosUrl + 'sushi.png';
      case "union":
        return logosUrl + 'union.png';
      default:
        return null;
    }
  }

  const clientName = projectName.match(/\[([^\)]+)\]/);
  const clearTitle = projectName.replace(/(\[\w+\] )/g, "");
  const logoSrc = clientName && getIconSrc(clientName[1]);

  return (
    <div className="Projects-blocks-item-info">
      {logoSrc && <img alt={`Logo of client ${clientName[1]}`} src={logoSrc} style={{ marginBottom: 12 }} width={60} height={60} />}
      <ProjectsBlockItemHeader heading={clearTitle} />
      <ProjectsBlockItemSmall small={projectCompany} />
    </div>
  )
};

export default ProjectsBlockItemInfo;
