import React from 'react';
import './hero.css';
import HeroContainer from './HeroContainer/heroContainer';

const Hero = ({heading, description}) => {

  return (
    <section className="Hero">
        <HeroContainer heading={heading} description={description}/>
    </section>
  );
};

export default Hero;
