import * as React from 'react';
import './projectsBlockItemHeading.css';

interface ProjectsBlockItemHeadingProps {
  heading: string;
}

const ProjectsBlockItemHeading: React.FunctionComponent<ProjectsBlockItemHeadingProps> = ({ heading }) => (
    <h3 className="Projects-block-item-heading">{heading}</h3>
);

export default ProjectsBlockItemHeading;
