import * as React from 'react';
import { useContext } from 'react';
import { Context } from '../../../Store/store';
import './heroHeadingContainer.css';

import HeroHeadingTextLG from './HeroHeadingText/HeroHeadingTextLG/heroHeadingTextLG';
import HeroHeadingTextMD from './HeroHeadingText/HeroHeadingTextMD/heroHeadingTextMD';

const name = 'Artyom Alekseev';
const nameRus = 'Артём Алексеев';
const about = 'Product Designer who loves to make simple and user-friendly design.';
const aboutRus = 'Product Designer который любит делать простой и удобный дизайн.';
const company = { name: 'Cactus Vision', website: 'http://cactus.vision' };
const companyRus = { name: 'Кактус Вижн', website: 'http://cactus.vision' };

const HeroHeadingContainer: React.FunctionComponent = ({heading, description}) => {
  const { state } = useContext(Context);

  return (
    <div className="Hero-heading-container">
        {state.changeLanguage === 'RU' ?
        <>
            <HeroHeadingTextLG text={heading ? heading : nameRus}/>
            <HeroHeadingTextMD text={description ? description : aboutRus} company={companyRus}/>
        </>
        : <>
            <HeroHeadingTextLG text={heading ? heading : name}/>
            <HeroHeadingTextMD text={description ? description : about} company={company}/>
        </>

        }
    </div>);
};

export default HeroHeadingContainer;
