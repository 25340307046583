import React from 'react';
import { ProjectsData } from '../../../../types/types';
import './projectsBlocks.css';
import ProjectsBlocksItem from './ProjectsBlocksItem/projectsBlocksItem';

export interface ProjectsBlocksProps {
  projects: ProjectsData[];
}
const ProjectsBlocks = ({ projects }: ProjectsBlocksProps) => {
  return (
    <div className="Projects-blocks">
      {projects.map((val, ind) => <ProjectsBlocksItem node={val} key={ind} ind={ind} />)}
    </div>
  );
};

export default ProjectsBlocks;
